import React from "react";
import Layout from "../components/layout";
import { useIntl } from "gatsby-plugin-intl";
import { graphql } from "gatsby";
import Gallery from "../components/gallery";
import { useState } from "react";
import GalleryFilter from "../components/gallery-filter";
import SEO from "../components/seo";

const Galerija = ({ data }) => {
  const intl = useIntl();
  const slike = data.allFile.edges;
  console.log(slike)
  const [filter, setFilter] = useState("galerija");

  const handleChangeFilter = (newFilter) => {
    setFilter(newFilter);
  };
  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "galerija" })} />
      <div className="section blue-background galerija">
        <div className="content">
          <div className="card">
            <h1> {intl.formatMessage({ id: "galerija" })}</h1>

            <Gallery
              slike={slike.filter((slika) =>
                slika.node.relativeDirectory.includes(filter)
              )}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Galerija;

export const query = graphql`
  query {
    allFile(
      filter: { relativeDirectory: { regex: "/galerija/" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(sizes: "(min-width: 10px) 2000px, 2000px", maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
          id
          relativeDirectory
        }
      }
    }
  }
`;
